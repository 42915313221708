import React, { useState, useEffect } from "react"
import { Container } from 'react-bootstrap';
import moment from "moment"

import { postFormData } from "../api/Api"
import '../assets/styles/_index.scss'
import LoaderIcon from '../assets/img/loader_icon.svg'

import axios from "axios"
import * as qs from "query-string"

import StepOne from "./StepOne/StepOne";
import StepTwo from "./StepTwo/StepTwo";
import { getUser } from "@myaccount/website-service";
import { createContext } from "react";
export const FromContext = createContext()

function ContactForm(props) {

  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showthankyou, setThankyou] = useState(false);
  const [formLoading, setFormLoading] = useState(false);

  const [formvalues, setFormvalues] = useState("");

  const [token, setToken] = useState("");

  const myRef = React.createRef();

  const recaptchaRef = React.createRef();

  const fields = ([
      {
        element: "config",
        formname: "website-viewing",
        error_text: "Highlighted fields are required | invalid",
        success_text: "Thank you for contacting us. A member of our team will contact you shortly.",
        email_temp_user: "contact_user",
        email_temp_admin: "contact_admin",
        email_server_func: "contact",
        event_tracking: "contact",
        page_url: "/contact"
      },
      {
        grpmd: "12",
        label: "Your name",
        placeholder: "",
        name: "name",
        type: "text",
        element: "input",
        required: true,
        patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
        class: "mb-4"
      },
      {
        grpmd: "12",
        label: "Email",
        placeholder: "",
        name: "email",
        type: "email",
        element: "input",
        required: true,
        patternchk:"[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",
        class: "mb-4"
      },
      {
        grpmd: "12",
        label: "Telephone Number",
        placeholder: "",
        name: "telephone",
        type: "text",
        element: "input",
        required: true,
        patternchk:"^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
        class: "mb-4"
      },
      {
        grpmd: "12",
        label: "Calendar",
        placeholder: "",
        name: "date_time",
        element: "calendar",
        class: "mb-4",
        rows:"3"
      },
      // {
      //   grpmd: "12",
      //   label: "Message",
      //   placeholder: "",
      //   name: "message",
      //   element: "textarea",
      //   class: "mb-4",
      //   rows:"3"
      // },
      {
        name: "Send Message",
        type:"submit",
        element: "button",
        value: "submit",
        class: "btn btn-primary"
      },
      {
        text: 'By clicking Send Message, you agree to our <a href="/terms-of-use" className="content-link">Terms & Conditions</a> and <a href="/privacy-policy" className="content-link">Privacy Policy</a>. ',
        element: "html",
        class: "policy"
      },
      {
        element: "captcha",
        class: "py-2",
        captchaRef: recaptchaRef
      },
    ]);

  const handlechange = event => {
    // remove initial empty spaces
    event.target.value = event.target.value.trimStart()
  }

  const userData = getUser();

  const [step, setStep] = useState(1);
  const [inputValues, setInputValues] = useState({
    name: '',
    email: '',
    telephone: '',
    date_time: '',
    // message: ''
  });
  const [errors, setErrors] = useState({});

  const nextStep = () => {
    setErrors({});
    const { name, email, telephone } = inputValues;
    if(step === 1) {
      let errorStatus = 0;
      if(!name || name === '') {
        setErrors(prevState => ({
          ...prevState,
          name: 'Please enter the name'
        }));

        errorStatus = 1;
      }

      if(!email || email === '') {
        setErrors(prevState => ({
          ...prevState,
          email: 'Please enter the email'
        }));
        errorStatus = 1;
      } else if (email.match("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$") === null) {
        setErrors(prevState => ({
          ...prevState,
          email: 'Please enter the valid email'
        }));
        errorStatus = 1;
      }

      if(!telephone || telephone === '') {
        setErrors(prevState => ({
          ...prevState,
          telephone: 'Please enter the telephone'
        }));
        errorStatus = 1;
      } else if (telephone.match("^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$") === null) {
        setErrors(prevState => ({
          ...prevState,
          telephone: 'Please enter the valid telephone'
        }));
        errorStatus = 1;
      }

      if(errorStatus === 0) {
        setErrors({});
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
        setStep(step + 1)
      }
    }
  };

  const prevStep = () => {
    setStep(step - 1);
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  const handleChange = (event) => {
    sessionStorage.setItem(event.target.name, event.target.value);
    let value = {
      ...inputValues,
      [event.target.name]: event.target.value
    }
    setInputValues(value);

    let error = {
      ...errors,
      [event.target.name]: null
    }
    setErrors(error);
  }

  const handleDateChange = (selectedDate) => {
    sessionStorage.setItem('date_time', selectedDate);
    let value = {
      ...inputValues,
      date_time: selectedDate
    }
    setInputValues(value);

    let error = {
      ...errors,
      date_time: null
    }
    setErrors(error);
  }

  const handleSave = (event) => {
    setErrors({});
    const { date_time, message } = inputValues;
    let errorStatus = 0;
    if(!date_time || date_time === '') {
      setErrors(prevState => ({
        ...prevState,
        date_time: 'Please select the date_time'
      }));

      errorStatus = 1;
    }

    // if(!message || message === '') {
    //   setErrors(prevState => ({
    //     ...prevState,
    //     message: 'Please enter the message'
    //   }));
    //
    //   errorStatus = 1;
    // }

    if(errorStatus == 0) {
      let json = {...inputValues};
      json['email_temp_user'] = fields[0].email_temp_user;
      json['email_temp_admin'] = fields[0].email_temp_admin;
      json['formname'] = fields[0].formname;
      json['g-recaptcha-response'] = token;
      let propertyData = JSON.parse(sessionStorage.getItem('bookviewproperty'));
      json['property_id'] = propertyData.property_id;
      json['negotiator_id'] = propertyData.negotiator_id;
      setInputValues(json);

      // recaptchaRef.current.execute();
      setToken(Math.random());
      setFormLoading(true)
    }
  }

  useEffect(() => {
    let sessionValues = {
      name: sessionStorage.getItem('name') != null ? sessionStorage.getItem('name') : userData?.name || '',
      email: sessionStorage.getItem('email') != null ? sessionStorage.getItem('email') : userData?.email || '',
      telephone: sessionStorage.getItem('telephone') != null ? sessionStorage.getItem('telephone') : userData?.mobile || '',
      date_time: sessionStorage.getItem('date_time') != null ? sessionStorage.getItem('date_time') : userData?.date_time || '',
      // message: sessionStorage.getItem('message') != null ? sessionStorage.getItem('message') : userData?.message || ''
    };

    setInputValues(sessionValues);
  }, []);

  useEffect(() => {
    if (token !== '') {

        let formData = new FormData();

        formData.append('data', JSON.stringify(
            {
                ...inputValues,
                date_time: moment(inputValues['date_time']).format("YYYY-MM-DD HH:mm")
            }
        ));

        postFormData(formData).then(apiRes => {

        if(apiRes?.form?.id || apiRes?.success){
            //setThankyou(true)
            setTimeout(() => {
                setThankyou(true);
                setFormLoading(false);
            }, 1000);

        } else{
            setFormLoading(false);
        }

          // lets send mail
          // const axiosOptions_email = {
          //   url: '/.netlify/functions/server/'+fields[0].email_server_func,
          //   method: "post",
          //   headers: { "Content-Type": "application/x-www-form-urlencoded" },
          //   data: qs.stringify(formvalues),
          // }
          //
          // axios(axiosOptions_email)
          //   .then(response => {
          //     console.log('mail sent!')
          //   })
          //   .catch(err =>
          //     console.log(err)
          //   );

          // formvalues['g-recaptcha-response'] = token;

          // const axiosOptions_netlify = {
          //   url: fields[0].page_url,
          //   method: "post",
          //   headers: { "Content-Type": "application/x-www-form-urlencoded" },
          //   data: qs.stringify(formvalues),
          // }

          const url = typeof window !== 'undefined' ? window.location.href : ''
          if (url.indexOf("property") > -1 && fields[4].event_tracking_decider) {
            fields[0].formname = 'Get mortgage help';
          }
          // tracking event
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            'event': 'formSubmit',
            'formType': 'form-'+fields[0].event_tracking,
            'formId': 'form-'+fields[0].event_tracking,
            'formName': fields[0].formname,
            'formLabel': fields[0].formname
          });

          setShowerror(false);
          // setThankyou(true);

          setStep(1);
          setInputValues({});

        });

        myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })

    }
  }, [token]);

  const handleonVerify = token => {
    console.log("captcha verified");
    setToken(token);
  };

  // const handleSubmit = event => {
  //     console.log("test => ", event);
  //   const form = event.currentTarget;

  //   if (form.checkValidity() === false) {
  //        console.log("test => fail");
  //     event.preventDefault();
  //     event.stopPropagation();
  //     setShowerror(true);
  //     setValidated(true);
  //     setThankyou(false);
  //     myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
  //   }
  //   else {
  //       console.log("test => Success");
  //     event.preventDefault();

  //     const formsdata = (event.target);
  //     const json = {}
  //     Object.keys(formsdata).map(key => (
  //       json[formsdata[key].name] = (formsdata[key].checked) ? 'yes' : formsdata[key].value
  //     ))

  //     json['email_temp_user'] = fields[0].email_temp_user;
  //     json['email_temp_admin'] = fields[0].email_temp_admin;
  //     json['formname'] = fields[0].formname;
  //     if(json['subject']) {
  //       json['formname']= 'Get mortgage help';
  //     }
  //     json['g-recaptcha-response'] = token;

  //     console.log("test => Success", json);

  //     setFormvalues(json);

  //     recaptchaRef.current.execute();

  //     setValidated(false);

  //     // reset form
  //     const form = event.target
  //     form.reset();

  //   }
  // };

  return (
    <div className="form stbform">
    <div ref={myRef} />

    {showerror && <div className="alert-error">
      <p>{fields[0].error_text}</p>
    </div>}

    {formLoading && <div className="form_loading"><LoaderIcon /></div>}

    {showthankyou && <div className="alert-success">
      <p>{fields[0].success_text}</p>
    </div>}

    {step === 1 ?  <StepOne nextStep={nextStep} handleChange={handleChange} inputValues={inputValues} errors={errors} /> : <StepTwo prevStep={prevStep} handleChange={handleChange} inputValues={inputValues} handleSave={handleSave} handleDateChange={handleDateChange} /> }

      {/* <Form name={fields[0].formname} action="/thank-you/" method="post" noValidate validated={validated} onSubmit={handleSubmit} data-netlify="true" netlify-honeypot="bot-field" data-netlify-recaptcha="true">
        <input type="hidden" name="form-name" value={fields[0].formname} />
        <input type="hidden" name="bot-field" />
        <Form.Row>
        {fields.map((field, index) => {
            console.log("dddddddddddd", field.element)
            if ( "input" === field.element ) {
              return (
                <InputField
                  name={field.name}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  type={field.type}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  pattern={field.patternchk}
                  handlechange={handlechange}
                />
              );
            }
            if ("select" === field.element) {
              return (
                <SelectField
                  name={field.name}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  required={field.required}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  values={field.values}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                  componentprops={props}
                />
              );
            }
            if ("textarea" === field.element) {
              return (
                <TextAreaField
                  name={field.name}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  rows={field.rows}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("checkbox" === field.element) {
              return (
                <CheckboxField
                  name={field.name}
                  ref={field.ref}
                  value={field.value}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("calendar" === field.element) {
                console.log("calendar => ", field.element);
              return (
                <CalendarBlock
                  name={field.name}
                  ref={field.ref}
                  value={field.value}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("html" === field.element) {
              return (
                <HtmlBox
                  text={field.text}
                  fieldClass={field.class}
                  key={`${field.element}~${index}`}
                />
              );
            }
            if ("captcha" === field.element) {
              return (
                <ReCaptchaBox
                  fieldClass={field.class}
                  captRef={field.captchaRef}
                  key={`${field.element}~${index}`}
                  handleonVerify={handleonVerify}
                />
              );
            }
            if ("button" === field.element) {
              return (
                <ButtonField
                  name={field.name}
                  fieldClass={field.class}
                  type={field.type}
                  value={field.value}
                  key={`${field.element}~${index}`}
                />
              );
            }
          })
        }
        </Form.Row>
      </Form> */}
    </div>
  );
}


const ContactFormPage = (props) => {

  /*const dummyData = {
    "day_start": 7,
    "day_end": 20,
    "duration": 15,
    "office_id": "OXF",
    "is_unaccompanied": false,
    "slots": {
        "2023-01-18": [
            {
                "negotiator_id": "JAS",
                "start_time": "8:00",
                "end_time": "8:15",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "8:25",
                "end_time": "8:40",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "8:50",
                "end_time": "9:05",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "9:15",
                "end_time": "9:30",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "10:30",
                "end_time": "10:45",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "10:55",
                "end_time": "11:10",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "11:20",
                "end_time": "11:35",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "11:45",
                "end_time": "12:00",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "12:10",
                "end_time": "12:25",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "13:50",
                "end_time": "14:05",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "14:15",
                "end_time": "14:30",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "14:40",
                "end_time": "14:55",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "15:05",
                "end_time": "15:20",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "15:30",
                "end_time": "15:45",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "15:55",
                "end_time": "16:10",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "16:20",
                "end_time": "16:35",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "16:45",
                "end_time": "17:00",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "17:10",
                "end_time": "17:25",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "17:35",
                "end_time": "17:50",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "18:00",
                "end_time": "18:15",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "18:25",
                "end_time": "18:40",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "18:50",
                "end_time": "19:05",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "19:15",
                "end_time": "19:30",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "19:40",
                "end_time": "19:55",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "20:05",
                "end_time": "20:20",
                "duration": 15
            }
        ],
        "2023-01-19": [
            {
                "negotiator_id": "JAS",
                "start_time": "8:00",
                "end_time": "8:15",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "8:25",
                "end_time": "8:40",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "8:50",
                "end_time": "9:05",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "9:15",
                "end_time": "9:30",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "10:30",
                "end_time": "10:45",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "10:55",
                "end_time": "11:10",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "11:20",
                "end_time": "11:35",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "11:45",
                "end_time": "12:00",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "12:10",
                "end_time": "12:25",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "12:35",
                "end_time": "12:50",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "13:00",
                "end_time": "13:15",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "13:25",
                "end_time": "13:40",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "13:50",
                "end_time": "14:05",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "14:15",
                "end_time": "14:30",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "14:40",
                "end_time": "14:55",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "15:05",
                "end_time": "15:20",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "15:30",
                "end_time": "15:45",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "15:55",
                "end_time": "16:10",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "16:20",
                "end_time": "16:35",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "16:45",
                "end_time": "17:00",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "17:10",
                "end_time": "17:25",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "17:35",
                "end_time": "17:50",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "18:00",
                "end_time": "18:15",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "18:25",
                "end_time": "18:40",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "18:50",
                "end_time": "19:05",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "19:15",
                "end_time": "19:30",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "19:40",
                "end_time": "19:55",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "20:05",
                "end_time": "20:20",
                "duration": 15
            }
        ],
        "2023-01-20": [
            {
                "negotiator_id": "JAS",
                "start_time": "8:00",
                "end_time": "8:15",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "8:25",
                "end_time": "8:40",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "8:50",
                "end_time": "9:05",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "9:15",
                "end_time": "9:30",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "11:45",
                "end_time": "12:00",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "12:10",
                "end_time": "12:25",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "13:00",
                "end_time": "13:15",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "13:25",
                "end_time": "13:40",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "13:50",
                "end_time": "14:05",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "14:15",
                "end_time": "14:30",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "14:40",
                "end_time": "14:55",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "15:05",
                "end_time": "15:20",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "15:30",
                "end_time": "15:45",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "15:55",
                "end_time": "16:10",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "16:20",
                "end_time": "16:35",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "16:45",
                "end_time": "17:00",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "17:10",
                "end_time": "17:25",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "17:35",
                "end_time": "17:50",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "18:00",
                "end_time": "18:15",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "18:25",
                "end_time": "18:40",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "18:50",
                "end_time": "19:05",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "19:15",
                "end_time": "19:30",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "19:40",
                "end_time": "19:55",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "20:05",
                "end_time": "20:20",
                "duration": 15
            }
        ],
        "2023-01-21": [
            {
                "negotiator_id": "JAS",
                "start_time": "7:00",
                "end_time": "7:10",
                "duration": 10
            },
            {
                "negotiator_id": "JAS",
                "start_time": "7:25",
                "end_time": "7:35",
                "duration": 10
            },
            {
                "negotiator_id": "JAS",
                "start_time": "7:50",
                "end_time": "8:00",
                "duration": 10
            },
            {
                "negotiator_id": "JAS",
                "start_time": "8:15",
                "end_time": "8:25",
                "duration": 10
            },
            {
                "negotiator_id": "JAS",
                "start_time": "8:40",
                "end_time": "8:50",
                "duration": 10
            },
            {
                "negotiator_id": "JAS",
                "start_time": "9:05",
                "end_time": "9:15",
                "duration": 10
            },
            {
                "negotiator_id": "JAS",
                "start_time": "10:45",
                "end_time": "10:55",
                "duration": 10
            },
            {
                "negotiator_id": "JAS",
                "start_time": "11:10",
                "end_time": "11:20",
                "duration": 10
            },
            {
                "negotiator_id": "JAS",
                "start_time": "11:35",
                "end_time": "11:45",
                "duration": 10
            },
            {
                "negotiator_id": "JAS",
                "start_time": "12:00",
                "end_time": "12:10",
                "duration": 10
            },
            {
                "negotiator_id": "JAS",
                "start_time": "12:25",
                "end_time": "12:35",
                "duration": 10
            },
            {
                "negotiator_id": "JAS",
                "start_time": "12:50",
                "end_time": "13:00",
                "duration": 10
            },
            {
                "negotiator_id": "JAS",
                "start_time": "13:15",
                "end_time": "13:25",
                "duration": 10
            },
            {
                "negotiator_id": "JAS",
                "start_time": "13:40",
                "end_time": "13:50",
                "duration": 10
            },
            {
                "negotiator_id": "JAS",
                "start_time": "14:05",
                "end_time": "14:15",
                "duration": 10
            },
            {
                "negotiator_id": "JAS",
                "start_time": "14:30",
                "end_time": "14:40",
                "duration": 10
            },
            {
                "negotiator_id": "JAS",
                "start_time": "14:55",
                "end_time": "15:05",
                "duration": 10
            },
            {
                "negotiator_id": "JAS",
                "start_time": "15:20",
                "end_time": "15:30",
                "duration": 10
            },
            {
                "negotiator_id": "JAS",
                "start_time": "15:45",
                "end_time": "15:55",
                "duration": 10
            }
        ],
        "2023-01-23": [
            {
                "negotiator_id": "JAS",
                "start_time": "8:00",
                "end_time": "8:15",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "8:25",
                "end_time": "8:40",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "8:50",
                "end_time": "9:05",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "9:15",
                "end_time": "9:30",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "10:30",
                "end_time": "10:45",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "10:55",
                "end_time": "11:10",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "11:20",
                "end_time": "11:35",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "11:45",
                "end_time": "12:00",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "12:10",
                "end_time": "12:25",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "12:35",
                "end_time": "12:50",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "13:00",
                "end_time": "13:15",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "13:25",
                "end_time": "13:40",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "13:50",
                "end_time": "14:05",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "14:15",
                "end_time": "14:30",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "15:30",
                "end_time": "15:45",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "15:55",
                "end_time": "16:10",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "16:20",
                "end_time": "16:35",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "16:45",
                "end_time": "17:00",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "17:10",
                "end_time": "17:25",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "17:35",
                "end_time": "17:50",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "18:00",
                "end_time": "18:15",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "18:25",
                "end_time": "18:40",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "18:50",
                "end_time": "19:05",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "19:15",
                "end_time": "19:30",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "19:40",
                "end_time": "19:55",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "20:05",
                "end_time": "20:20",
                "duration": 15
            }
        ],
        "2023-01-24": [
            {
                "negotiator_id": "JAS",
                "start_time": "8:00",
                "end_time": "8:15",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "8:25",
                "end_time": "8:40",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "8:50",
                "end_time": "9:05",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "9:15",
                "end_time": "9:30",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "10:30",
                "end_time": "10:45",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "10:55",
                "end_time": "11:10",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "11:20",
                "end_time": "11:35",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "11:45",
                "end_time": "12:00",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "12:10",
                "end_time": "12:25",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "13:00",
                "end_time": "13:15",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "13:25",
                "end_time": "13:40",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "13:50",
                "end_time": "14:05",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "14:15",
                "end_time": "14:30",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "14:40",
                "end_time": "14:55",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "15:05",
                "end_time": "15:20",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "15:30",
                "end_time": "15:45",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "15:55",
                "end_time": "16:10",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "16:20",
                "end_time": "16:35",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "16:45",
                "end_time": "17:00",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "17:10",
                "end_time": "17:25",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "17:35",
                "end_time": "17:50",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "18:00",
                "end_time": "18:15",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "18:25",
                "end_time": "18:40",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "18:50",
                "end_time": "19:05",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "19:15",
                "end_time": "19:30",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "19:40",
                "end_time": "19:55",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "20:05",
                "end_time": "20:20",
                "duration": 15
            }
        ],
        "2023-01-25": [
            {
                "negotiator_id": "JAS",
                "start_time": "8:00",
                "end_time": "8:15",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "8:25",
                "end_time": "8:40",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "8:50",
                "end_time": "9:05",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "9:15",
                "end_time": "9:30",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "10:30",
                "end_time": "10:45",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "10:55",
                "end_time": "11:10",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "13:50",
                "end_time": "14:05",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "14:15",
                "end_time": "14:30",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "14:40",
                "end_time": "14:55",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "15:05",
                "end_time": "15:20",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "15:30",
                "end_time": "15:45",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "15:55",
                "end_time": "16:10",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "17:35",
                "end_time": "17:50",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "18:00",
                "end_time": "18:15",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "18:25",
                "end_time": "18:40",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "18:50",
                "end_time": "19:05",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "19:15",
                "end_time": "19:30",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "19:40",
                "end_time": "19:55",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "20:05",
                "end_time": "20:20",
                "duration": 15
            }
        ],
        "2023-01-26": [
            {
                "negotiator_id": "JAS",
                "start_time": "8:00",
                "end_time": "8:15",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "8:25",
                "end_time": "8:40",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "8:50",
                "end_time": "9:05",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "9:15",
                "end_time": "9:30",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "11:45",
                "end_time": "12:00",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "12:10",
                "end_time": "12:25",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "13:00",
                "end_time": "13:15",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "13:25",
                "end_time": "13:40",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "13:50",
                "end_time": "14:05",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "14:15",
                "end_time": "14:30",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "14:40",
                "end_time": "14:55",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "15:05",
                "end_time": "15:20",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "15:30",
                "end_time": "15:45",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "15:55",
                "end_time": "16:10",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "16:20",
                "end_time": "16:35",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "16:45",
                "end_time": "17:00",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "17:10",
                "end_time": "17:25",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "17:35",
                "end_time": "17:50",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "18:00",
                "end_time": "18:15",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "18:25",
                "end_time": "18:40",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "18:50",
                "end_time": "19:05",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "19:15",
                "end_time": "19:30",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "19:40",
                "end_time": "19:55",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "20:05",
                "end_time": "20:20",
                "duration": 15
            }
        ],
        "2023-01-27": [
            {
                "negotiator_id": "JAS",
                "start_time": "8:00",
                "end_time": "8:15",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "8:25",
                "end_time": "8:40",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "8:50",
                "end_time": "9:05",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "9:15",
                "end_time": "9:30",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "10:30",
                "end_time": "10:45",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "10:55",
                "end_time": "11:10",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "11:20",
                "end_time": "11:35",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "11:45",
                "end_time": "12:00",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "12:10",
                "end_time": "12:25",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "12:35",
                "end_time": "12:50",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "13:00",
                "end_time": "13:15",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "13:25",
                "end_time": "13:40",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "13:50",
                "end_time": "14:05",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "14:15",
                "end_time": "14:30",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "14:40",
                "end_time": "14:55",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "15:05",
                "end_time": "15:20",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "15:30",
                "end_time": "15:45",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "15:55",
                "end_time": "16:10",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "16:20",
                "end_time": "16:35",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "16:45",
                "end_time": "17:00",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "17:10",
                "end_time": "17:25",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "17:35",
                "end_time": "17:50",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "18:00",
                "end_time": "18:15",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "18:25",
                "end_time": "18:40",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "18:50",
                "end_time": "19:05",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "19:15",
                "end_time": "19:30",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "19:40",
                "end_time": "19:55",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "20:05",
                "end_time": "20:20",
                "duration": 15
            }
        ],
        "2023-01-28": [
            {
                "negotiator_id": "JAS",
                "start_time": "7:00",
                "end_time": "7:10",
                "duration": 10
            },
            {
                "negotiator_id": "JAS",
                "start_time": "7:25",
                "end_time": "7:35",
                "duration": 10
            },
            {
                "negotiator_id": "JAS",
                "start_time": "7:50",
                "end_time": "8:00",
                "duration": 10
            },
            {
                "negotiator_id": "JAS",
                "start_time": "8:15",
                "end_time": "8:25",
                "duration": 10
            },
            {
                "negotiator_id": "JAS",
                "start_time": "8:40",
                "end_time": "8:50",
                "duration": 10
            },
            {
                "negotiator_id": "JAS",
                "start_time": "9:05",
                "end_time": "9:15",
                "duration": 10
            },
            {
                "negotiator_id": "JAS",
                "start_time": "10:45",
                "end_time": "10:55",
                "duration": 10
            },
            {
                "negotiator_id": "JAS",
                "start_time": "11:10",
                "end_time": "11:20",
                "duration": 10
            },
            {
                "negotiator_id": "JAS",
                "start_time": "11:35",
                "end_time": "11:45",
                "duration": 10
            },
            {
                "negotiator_id": "JAS",
                "start_time": "12:00",
                "end_time": "12:10",
                "duration": 10
            },
            {
                "negotiator_id": "JAS",
                "start_time": "12:50",
                "end_time": "13:00",
                "duration": 10
            },
            {
                "negotiator_id": "JAS",
                "start_time": "13:15",
                "end_time": "13:25",
                "duration": 10
            },
            {
                "negotiator_id": "JAS",
                "start_time": "13:40",
                "end_time": "13:50",
                "duration": 10
            },
            {
                "negotiator_id": "JAS",
                "start_time": "14:05",
                "end_time": "14:15",
                "duration": 10
            },
            {
                "negotiator_id": "JAS",
                "start_time": "14:30",
                "end_time": "14:40",
                "duration": 10
            },
            {
                "negotiator_id": "JAS",
                "start_time": "14:55",
                "end_time": "15:05",
                "duration": 10
            },
            {
                "negotiator_id": "JAS",
                "start_time": "15:20",
                "end_time": "15:30",
                "duration": 10
            },
            {
                "negotiator_id": "JAS",
                "start_time": "15:45",
                "end_time": "15:55",
                "duration": 10
            }
        ],
        "2023-01-30": [
            {
                "negotiator_id": "JAS",
                "start_time": "8:00",
                "end_time": "8:15",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "8:25",
                "end_time": "8:40",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "8:50",
                "end_time": "9:05",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "9:15",
                "end_time": "9:30",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "10:30",
                "end_time": "10:45",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "10:55",
                "end_time": "11:10",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "11:20",
                "end_time": "11:35",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "11:45",
                "end_time": "12:00",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "12:10",
                "end_time": "12:25",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "13:00",
                "end_time": "13:15",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "13:25",
                "end_time": "13:40",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "13:50",
                "end_time": "14:05",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "14:15",
                "end_time": "14:30",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "15:30",
                "end_time": "15:45",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "15:55",
                "end_time": "16:10",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "16:20",
                "end_time": "16:35",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "16:45",
                "end_time": "17:00",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "17:10",
                "end_time": "17:25",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "17:35",
                "end_time": "17:50",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "18:00",
                "end_time": "18:15",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "18:25",
                "end_time": "18:40",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "18:50",
                "end_time": "19:05",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "19:15",
                "end_time": "19:30",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "19:40",
                "end_time": "19:55",
                "duration": 15
            },
            {
                "negotiator_id": "JAS",
                "start_time": "20:05",
                "end_time": "20:20",
                "duration": 15
            }
        ]
    }
  }*/
  const dummyData = ''
  const [calendarData, setCalendarData] = useState(dummyData)
  const [loading, setLoading] = useState(false)

  return(
    <div
        className="contact only-title"
    >
        <Container>
          <FromContext.Provider value={{ calendarData, setCalendarData, loading, setLoading}}>
            <ContactForm subject={props.subject} />
          </FromContext.Provider>
        </Container>
    </div>
  )
}

export default ContactFormPage
