import React, { useState, useEffect } from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import AddressSearch from 'react-loqate';
import './StepTwo.scss';

const AddressSearchInput = (props) => {
    // console.log(props)
    // var SeLAdd = sessionStorage.getItem('SelectedAdress')
    // document.getElementById('address').value = SeLAdd

    return (
        <Form.Group>
            <div className="custom-float">
                <Form.Label className="form-label">What’s the address of your property?*</Form.Label>
                <input
                    name="address"
                    placeholder=""
                    autoComplete="off"
                    type="text"
                    id="address"
                    className="form-control"
                    {...props}
                    value={props.addressLabel}
                    required
                />
            </div>
        </Form.Group>
    );
};

const StepTwo = (props) => {
    const [validated, setValidated] = useState(false);

    const back = (e) => {
        e.preventDefault();
        props.prevStep();
    }

    const saveAndContinue = (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
        }

        setValidated(true);
        props.nextStep();
    }

    const handleAddress = (address) => {
        document.getElementById('address').value = address.Label.replace(/\n/g, ", ")
        sessionStorage.setItem('addressBuildingName', address.BuildingName);
        sessionStorage.setItem('addressBuildingNumber', address.BuildingNumber);
        sessionStorage.setItem('addressPostalCode', address.PostalCode);
        sessionStorage.setItem('addressSubBuilding', address.SubBuilding);
        sessionStorage.setItem('addressStreet', address.Street);
        sessionStorage.setItem("addressLabel", address.Label.replace(/\n/g, ", "));
    }

    useEffect(() => {
        var addressLabel = sessionStorage.getItem('addressLabel') != null ? sessionStorage.getItem('addressLabel') : ''
        document.getElementById('address').value = addressLabel
    }, []);

    return(
        <ScrollAnimation animateIn='fadeIn' animateOnce={true} delay={300}>
            <Form className="text-left" validated={validated}>
                <Form.Group className="address-input">
                    <AddressSearch
                        locale="en_GB"
                        apiKey={process.env.GATSBY_LOQATE_API_KEY}
                        countries={['GB']}
                        components={{ Input: AddressSearchInput }}
                        className="address-search-box"
                        inputClassname="address form-control"
                        listClassname="address-options"
                        listItemClassname="address-option"
                        onSelect={(address) => handleAddress(address)}
                        onChange={props.handleAddressChange}
                    />
                </Form.Group>

                <span className='steptwo-label'>Would you like to Sell or Let your property?</span>
                <Form.Group>
                    <Form.Check
                        inline
                        type="radio"
                        name="property_type"
                        id="default-radio"
                        label="Sell"
                        // required
                        value="sales"
                        checked={props?.inputValues?.sellorlet === "sales"}
                        onChange={props.handleChange}
                    />
                    <Form.Check
                        inline
                        type="radio"
                        name="property_type"
                        id="default-radio1"
                        label="Let"
                        // required
                        value="lettings"
                        checked={props?.inputValues?.sellorlet === "lettings"}
                        onChange={props.handleChange}
                    />
                </Form.Group>
                <Form.Group className="action-btn">
                    <Row>
                        <Col>
                            <Button variant="primary" onClick={back}>Back</Button>
                        </Col>
                        <Col>
                            <Button variant="primary" onClick={saveAndContinue}>Next</Button>
                        </Col>
                    </Row>
                </Form.Group>
            </Form>
        </ScrollAnimation>
    )
}

export default StepTwo;
