import React, { useState, useContext } from "react";
import { Button, Form, Row, Col } from 'react-bootstrap';
import CalendarBlock from '../../elements/CalendarBlock/CalendarBlock';
import "react-calendar/dist/Calendar.css";
import TextAreaField from '../../elements/textarea';
import HtmlBox from '../../elements/html';
import { FromContext } from "../book-a-viewing-form";
import axios from "axios"
import { getToken } from '@myaccount/website-service';

const StepTwo = (props) => {

    const [validated, setValidated] = useState(false);
    const { calendarData, setCalendarData, loading, setLoading } = useContext(FromContext);
    console.log("test => calendarData", calendarData)

    React.useEffect(() => {
        if (!calendarData && !loading) {
            setLoading(true)
            // const BASE_URL = "https://myaccount-package.q.starberry.com";
            // const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYyM2FhNGU2YWU0YjU3NjQ4ODdiYmY4YSIsImlhdCI6MTY3MzYwNTYzMCwiZXhwIjoxNjc2MTk3NjMwfQ.hdke485mkSHjD0W0S880_RH9bTC4FXCiO20zRK4KXM4"
            // // With header call
            // const authInstance = axios.create({
            //     baseURL: 'https://myaccount-package.q.starberry.com',
            //     headers: {
            //         Authorization: `Bearer ${token}`,
            //     },
            // });

            // authInstance.get('/stb-myaccount/property/MKT220287/getviewingavailabilities').then((res => {
            //     setCalendarData(res?.data)
            //     setLoading(false)
            // }))

            const BASE_URL = `${process.env.GATSBY_STRAPI_FORM_URL || process.env.GATSBY_STRAPI_SRC}`; // "https://myaccount-package.q.starberry.com";
            const token = getToken() || process.env.GATSBY_STRAPI_FORM_TOKEN; //"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYyM2FhNGU2YWU0YjU3NjQ4ODdiYmY4YSIsImlhdCI6MTY3MzYwNTYzMCwiZXhwIjoxNjc2MTk3NjMwfQ.hdke485mkSHjD0W0S880_RH9bTC4FXCiO20zRK4KXM4"
            const propertyData = JSON.parse(sessionStorage.getItem('bookviewproperty'));
            // With header call
            const authInstance = axios.create({
                baseURL: BASE_URL,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            authInstance.get(`/stb-myaccount/property/${propertyData.property_id}/getviewingavailabilities`).then((res => {
                setCalendarData(res?.data)
                setLoading(false)
            }))
        }
    })

    const fields = ([
        {
          grpmd: "12",
          label: "Calendar",
          placeholder: "",
          name: "date_time",
          element: "calendar",
          class: "mb-4",
          rows:"3",
          required: true,
        },
        {
          text: 'By clicking Send Message, you agree to our <a href="/terms-of-use" className="content-link">Terms & Conditions</a> and <a href="/privacy-policy" className="content-link">Privacy Policy</a>. ',
          element: "html",
          class: "policy"
        }
    ]);

    const back = (e) => {
        e.preventDefault();
        props.prevStep();
    }

    const save = (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
        }

        setValidated(true);
        props.handleSave();
    }

    return (
        <Form validated={validated}>
            <div className="steptwo">
                <div className="form-section">
                    <div className="form-block-col">
                        {loading ? (
                            <h4>Loading...</h4>
                        ) : (
                            <>
                            {fields.map((field, index) => {
                                if ("textarea" === field.element) {
                                    return (
                                    <TextAreaField
                                        name={field.name}
                                        grpmd={field.grpmd}
                                        label={field.label}
                                        ref={field.ref}
                                        rows={field.rows}
                                        fieldClass={field.class}
                                        placeholder={field.placeholder}
                                        required={field.required}
                                        key={`${field.element}~${index}`}
                                        handlechange={props.handleChange}
                                        value={props?.inputValues?.[field.name]}
                                    />
                                    );
                                }
                                if ("calendar" === field.element) {
                                return (
                                    <CalendarBlock
                                        name={field.name}
                                        ref={field.ref}
                                        fieldClass={field.class}
                                        placeholder={field.placeholder}
                                        required={field.required}
                                        key={`${field.name}~${index}`}
                                        handlechange={props.handleDateChange}
                                        value={props?.inputValues?.[field.name]}
                                        calendarData={calendarData}
                                    />
                                );
                                }
                                if ("html" === field.element) {
                                return (
                                    <HtmlBox
                                    text={field.text}
                                    fieldClass={field.class}
                                    key={`${field.element}~${index}`}
                                    />
                                );
                                }
                                })
                            }
                                <Form.Group className="col-md-12 action-btn">
                                    <Row>
                                        <Col>
                                            <Button variant="primary" onClick={back}>Back</Button>
                                        </Col>
                                        <Col>
                                            <Button variant="primary" onClick={save}>Send a Message</Button>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </>
                        )}

                    </div>
                </div>

            </div>
        </Form>
    )
};

export default StepTwo;
