import React, { useState } from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import CalendarBlock from '../../elements/CalendarBlock/CalendarBlock';
import "react-calendar/dist/Calendar.css";

const StepThree = (props) => {
    const [validated, setValidated] = useState(false);

    const back = (e) => {
        e.preventDefault();
        props.prevStep();
    }

    const save = (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
        }

        setValidated(true);
        props.handleSave();
    }

    return(
        <ScrollAnimation animateIn='fadeIn' animateOnce={true} delay={300}>
            <Form validated={validated}>
                <Form.Group>
                    <CalendarBlock
                        name="date_time"
                        fieldClass="mb-4"
                        placeholder=""
                        required
                        handlechange={props.handleDateChange}
                        value={props?.inputValues?.date_time}
                    />
                </Form.Group>
                <Form.Group className="action-btn">
                    <Row>
                        <Col>
                            <Button variant="primary" onClick={back}>Back</Button>
                        </Col>
                        <Col>
                            <Button variant="primary" onClick={save}>Send Message</Button>
                        </Col>
                    </Row>
                </Form.Group>
            </Form>
        </ScrollAnimation>
    )
}

export default StepThree;