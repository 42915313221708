import React, { useState } from "react";
import { Button, Form, } from 'react-bootstrap';
import InputField from '../../elements/input';
// import { useAuthState, isBrowser, doRedirect/*, storeLoginAttempt*/ } from "@myaccount/website-service";

const StepOne = (props) => {
    const [validated, setValidated] = useState(false);
    const fields = ([
        {
          grpmd: "12",
          label: "Your name",
          placeholder: "",
          name: "name",
          type: "text",
          element: "input",
          required: true,
          patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
          class: "mb-4"
        },
        {
          grpmd: "12",
          label: "Email",
          placeholder: "",
          name: "email",
          type: "email",
          element: "input",
          required: true,
          patternchk:"[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",
          class: "mb-4"
        },
        {
          grpmd: "12",
          label: "Telephone Number",
          placeholder: "",
          name: "telephone",
          type: "text",
          element: "input",
          required: true,
          patternchk:"^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
          class: "mb-4"
        }
    ]);
    
    // For myaccount
    // const { state: authState, services: authServices } = useAuthState()
    // const isAuthenticated = authServices.isAuthenticated()

    // if (isBrowser() && !isAuthenticated) {
    //   authServices.storeData("auth_return", window.location.href)
    //   doRedirect(process.env.GATSBY_MYACCOUNT_URL)
    // }

    // // My account
    // if (!isAuthenticated) {
    //     return (
    //       <div className="stepone"><h2>Loading...</h2></div>
    //     )
    // }

    const saveAndContinue = (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
        }

        setValidated(true);
        props.nextStep();
    }

    return (
        <Form validated={validated}>
            <div className="stepone">
                <div className="form-section">
                    <div className="form-block-col">
                        {fields.map((field, index) => {
                            if ( "input" === field.element ) {
                            return (
                                <InputField
                                    name={field.name}
                                    grpmd={field.grpmd}
                                    label={field.label}
                                    ref={field.ref}
                                    type={field.type}
                                    fieldClass={field.class}
                                    placeholder={field.placeholder}
                                    required={field.required}
                                    key={`${field.element}~${index}`}
                                    pattern={field.patternchk}
                                    handlechange={props.handleChange}
                                    errorMsg={props?.errors?.[field.name]}
                                    value={props?.inputValues?.[field.name]}
                                />
                            );
                            }
                            })
                        }
                        {/* <Form.Group>
                            <Form.Control className={props.nameError ? 'error-border' : ''} autoComplete="off" value={props.name} onChange={props.onChange} name="name1" type="text" placeholder="Your Name*" />
                            {props.nameError &&
                                <div className="error">
                                Please Enter Name.
                                </div>
                            }
                        </Form.Group>
                        <Form.Group>
                            <Form.Control className={props.emailError ? 'error-border' : props.valemailError ? 'error-border' : ''} autoComplete="off" value={props.email} onChange={props.onChange} name="email" type="email" placeholder="Email*" />
                            {props.emailError &&
                                <div className="error">
                                Please Enter Email.
                                </div>
                            }
                            {props.valemailError && !props.emailError &&
                                <div className="error">
                                Please Enter Valid Email.
                                </div>
                            }
                        </Form.Group>
                        <Form.Group>
                        <Form.Control maxLength={13} className={props.phoneError ? 'error-border' : props.valphoneError ? 'error-border' : ''} autoComplete="off" value={props.phone} onChange={props.onChange} name="telephone" type="text" placeholder="Telephone Number*" />
                            {props.phoneError &&
                                <div className="error">
                                Please Enter Telephone.
                                </div>
                            }
                            {props.valphoneError && !props.phoneError &&
                                <div className="error">
                                Please Enter Valid Telephone.
                                </div>
                            }
                        </Form.Group> */}
                        <Form.Group className="col-md-12 action-btn">
                            <Button variant="primary" className={'ful'} onClick={saveAndContinue}>Get Started</Button>
                        </Form.Group>

                    </div>
                </div>
            </div>
        </Form>
    )
};

export default StepOne;
