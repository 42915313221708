import React from "react";
import Helmet from 'react-helmet';
import { Container } from 'react-bootstrap';
import ReactMarkdown from "react-markdown"

/**
 * Layouts
 */
import Layout from '@layouts/default';

/**
 * Components
 */
import Intro from '@components/intro/intro';
import About from '@components/about/about';
import SeamlessExperience from '@components/seamless-experience/seamless-experience';
import SeamlessExperienceWithServices from '@components/seamless-experience/seamless-experience-with-service-links';
import PropertyControl from '@components/property-control/property-control';
import Reviews from '@components/reviews/reviews';
import BookVideo from '@components/book-video/book-video';
import AreYouLooking from '@components/book-video/are-you-looking';
import Newsletter from '@components/book-video/newsletter';
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs';
import EntryHeading from '@components/entry-heading/entry-heading';
import AllAreaGuides from '@components/global-collections/area-guides-list';
import MeettheTeam from '@components/meet-the-team/meet-the-team';
import TopBanner from '@components/top-banner/top-banner';
import PostContent from "@components/post-content/post-content";
import Accordion from "@components/post-content/accordion";
import PostSidebar from "@components/post-sidebar/post-sidebar";
import OfficeInfo from "@components/post-sidebar/office";
import Slider from '@components/property-slider/gallery-slider';
import AllNews from '@components/news-events-video/all-news';
import Calculators from '@components/property-info/calculators';
import ValuationBlock from '@components/valuation-block/valuation-block';
import Cta from '@components/cta/cta';
import Video from '@components/video/video';
import Relative from '@components/relative/relative';
import TeamSlider from "@components/team/team";
import ReviewsGrid from "@components/reviews-grid/reviews-grid";
import Contact from "@components/contact/contact";
import ApplyforJob from "@components/apply-for-job/apply-for-job";
import BookAViewingForm from '@components/forms/book-viewing/book-a-viewing-form';
import ValautionForm from '@components/forms/valuation/valuation-form';
import Cards from "@components/cards/cards";
import { graphql } from 'gatsby'
import get from 'lodash/get'
import SEO from "../components/seo"

const no_image = "/images/no-image.png"

class DefaultTemplate extends React.Component {

  render() {

    const Modules = get(this, 'props.data.strapiArticles.Add_Modules')
    const Global_Modules = get(this, 'props.data.strapiArticles.Global_Module')
    const Page = get(this, 'props.data.strapiArticles')
    const Team = get(this, 'props.data.allStrapiTeams.edges')
    const Calendly = get(this, 'props.data.strapiGlobalConfig.Calendly_Link')



    if(Page.Contact_Person != null)
    {
      if(Page.Contact_Person.Image == null)
      {
        var imgURl = no_image
      }
      else
      {
        var imgURl = Page.Contact_Person.Image.publicURL
      }
    }

    if(Page.Custom_CSS_Class == null) {
      var custom_css = ""
    }
    else
    {
      var custom_css = Page.Custom_CSS_Class
    }


    if(Page.office != null) {
      var office_class = "post-content__right office-details-sidebar"
    }
    else {
      var office_class = "post-content__right"
    }

    return (
      <Layout>
        <Helmet
          bodyAttributes={{
            class: `static templates-default-template-js ${custom_css}`
          }}
        />
        <SEO title={Page.Meta_Title} description={Page.Meta_Description} />
        <div className="page-body">
        {Modules.map((Add_Modules, i) => {
          return (
            <>
              {Add_Modules.Banner_Title != null &&
                <Intro Title={Add_Modules.Banner_Title} Content={Add_Modules.Content} CTA_1_Label={Add_Modules.CTA_1_Label} CTA_1_URL={Add_Modules.CTA_1_URL} CTA_2_Label={Add_Modules.CTA_2_Label} CTA_2_URL={Add_Modules.CTA_2_URL} Show_Google_Rating={Add_Modules.Show_Google_Rating} Upload_Video={Add_Modules.Upload_Video} Banner_Image={Add_Modules.Banner_Image} Embed_Video_URL={Add_Modules.Embed_Video_URL} Speak_with={Add_Modules.Speak_with} height="742px" Calendly={Calendly} Custom_CSS="intro_style_2" size="930x1080"/>
              }

              {Add_Modules.Title_H1 != null && Page.Layout != "Static_Landing_Page" &&
                <Container>
                  <EntryHeading Title={Add_Modules.Title_H1} Sub_Title={Add_Modules.Sub_Title} CTA_1_Label={Add_Modules.CTA_1_Label} CTA_1_URL={Add_Modules.CTA_1_URL} CTA_2_Label={Add_Modules.CTA_2_Label} CTA_2_URL={Add_Modules.CTA_2_URL} Content={Add_Modules.Content} Content_Alignment={Add_Modules.Content_Alignment} Calendly={Calendly}/>
                </Container>
              }

              {Add_Modules.Services != null &&
                <About Title={Add_Modules.Title} Content={Add_Modules.Content} Services={Add_Modules.Services} CTA_Label={Add_Modules.CTA_Label} CTA_URL={Add_Modules.CTA_URL}/>
              }

              {Add_Modules.Add != null &&
                <ValuationBlock data={Add_Modules.Add}/>
              }

              {Add_Modules.Teams != null &&
                <TeamSlider Title={Add_Modules.Title} Content={Add_Modules.Content} data={Add_Modules.Teams}/>
              }

              {Add_Modules.Video_Background_Image != null && Add_Modules.Layout == "Header_Banner" &&
                <TopBanner Upload_Video={Add_Modules.Upload_Video} Banner_Image={Add_Modules.Video_Background_Image} Embed_Video_URL={Add_Modules.Embed_Video_URL} size="1600x650"/>
              }

              {Add_Modules.Service_Title != null &&
              <>

                {Add_Modules.Choose_Style == "Style_1_White_Background" &&

                <>
                {Add_Modules.Services_Links == null &&
                  <SeamlessExperience Title={Add_Modules.Service_Title} Content={Add_Modules.Content} Embed_Video_URL={Add_Modules.Embed_Video_URL} CTA_Label={Add_Modules.CTA_Label} CTA_URL={Add_Modules.CTA_URL} Upload_Video={Add_Modules.Upload_Video} Image={Add_Modules.Image} Embed_Video_URL={Add_Modules.Embed_Video_URL} Image_Alignment={Add_Modules.Image_Alignment} Calendly={Calendly} size="890x600"/>
                }
                {Add_Modules.Services_Links != null &&
                  <SeamlessExperienceWithServices Title={Add_Modules.Service_Title} Content={Add_Modules.Content} Embed_Video_URL={Add_Modules.Embed_Video_URL} CTA_Label={Add_Modules.CTA_Label} CTA_URL={Add_Modules.CTA_URL} Upload_Video={Add_Modules.Upload_Video} Image={Add_Modules.Image} Embed_Video_URL={Add_Modules.Embed_Video_URL} ServiceLinks={Add_Modules.Services_Links} Image_Alignment={Add_Modules.Image_Alignment} Calendly={Calendly} size="780x600"/>
                }
                </>
                }

                {Add_Modules.Choose_Style == "Style_2_Grey_Background" &&
                  <PropertyControl Title={Add_Modules.Service_Title} Content={Add_Modules.Content} Embed_Video_URL={Add_Modules.Embed_Video_URL} CTA_Label={Add_Modules.CTA_Label} CTA_URL={Add_Modules.CTA_URL} Upload_Video={Add_Modules.Upload_Video} Image={Add_Modules.Image} Embed_Video_URL={Add_Modules.Embed_Video_URL} Calendly={Calendly}/>
                }
              </>
              }

            </>
          )
        })}


        {Page.Layout == "Static_Landing_Page" &&
        <Container>
          <div className="post-content">
              <div className="post-content__inner">
                  <div className="post-content__left">
                    {Modules.map((Add_Modules, i) => {
                    if(i == 0)
                    {
                      var additional_entry_class = ""
                    }
                    else {
                      var additional_entry_class = "entry-heading-head"
                    }
                    return (
                      <>
                      {Add_Modules.Title_H1 != null &&
                        <EntryHeading Title={Add_Modules.Title_H1} Sub_Title={Add_Modules.Sub_Title} CTA_1_Label={Add_Modules.CTA_1_Label} CTA_1_URL={Add_Modules.CTA_1_URL} CTA_2_Label={Add_Modules.CTA_2_Label} CTA_2_URL={Add_Modules.CTA_2_URL} Content={Add_Modules.Content} Content_Alignment={Add_Modules.Content_Alignment} Calendly={Calendly}/>
                      }

                      <div className={additional_entry_class}>
                        <PostContent Content={Add_Modules.Static_Content} CTA_Label={Add_Modules.CTA_Label} CTA_URL={Add_Modules.CTA_URL} Calendly={Calendly}/>
                      </div>
                      {Add_Modules.Add_Accordion != null &&
                        <Accordion items={Add_Modules.Add_Accordion}/>
                      }
                      {Add_Modules.Choose_Images != null &&
                        <Slider slides={Add_Modules.Choose_Images}/>
                      }

                      {Add_Modules.Help_Title != null &&
                        <Cta link={`/${Add_Modules.Help_CTA_URL}`}  button={Add_Modules.Help_CTA_Label}>
                            <h5>{Add_Modules.Help_Title}</h5>
                            <p><ReactMarkdown source={Add_Modules.Help_Content} /></p>
                        </Cta>
                      }

                      {Add_Modules.Video_Background_Image != null && Add_Modules.Layout == "Default" &&
                        <Relative>
                            <Video
                                height="500px"
                                contain={true}
                                image={Add_Modules.Video_Background_Image}
                                size="840x500"
                                video={Add_Modules.Upload_Video.publicURL}
                            />
                        </Relative>
                      }
                      </>
                    )
                  })}
                  </div>
                  <div className={office_class}>
                    {Page.Contact_Person != null &&
                      <PostSidebar Name={Page.Contact_Person.Name} Designation={Page.Contact_Person.Designation} Email={Page.Contact_Person.Email} Phone={Page.Contact_Person.Phone} Image={imgURl} Calendly={Calendly} AdditionalClass="sidebar-pad-200"/>
                    }
                    {Page.office != null &&
                      <OfficeInfo Name={Page.office.Office_Name} Image={Page.office.Office_Image.publicURL} Address={Page.office.Address} Opening_Hours={Page.office.Opening_Hours} Phone={Page.office.Phone}/>
                    }
                  </div>
              </div>
          </div>
        </Container>
        }


        {Global_Modules.map((Global_Modules, i) => {
          return (
            <>

              {Global_Modules.Please_Choose == "All_Area_Guides" &&
                <AllAreaGuides Parent={Page.Parent.URL} Page={Page.URL} />
              }

              {Global_Modules.Please_Choose == "Reviews" &&
                <Reviews />
              }

              {Global_Modules.Please_Choose == "Calculators" &&
                <Calculators />
              }

              {Global_Modules.Please_Choose == "Team_Members" &&
                <MeettheTeam items={Team}/>
              }

              {Global_Modules.Please_Choose == "All_News_Events" &&
                <AllNews/>
              }

              {Global_Modules.Please_Choose == "All_Offices" &&
                <Cards/>
              }

              {Global_Modules.Please_Choose == "Book_A_Call" &&
                <BookVideo />
              }

              {Global_Modules.Please_Choose == "Looking_to_Sell_or_Let" &&
                <AreYouLooking />
              }

              {Global_Modules.Please_Choose == "Newsletter_Subscribe" &&
                <Newsletter />
              }

              {Global_Modules.Please_Choose == "All_Reviews" &&
                <ReviewsGrid />
              }

              {Global_Modules.Please_Choose == "Contact_Form" &&
                <Contact />
              }

              {Global_Modules.Please_Choose == "Career_Form" &&
                <ApplyforJob />
              }
              {Global_Modules.Please_Choose == "Book_A_Viewing_Form" &&
                <BookAViewingForm />
              }
              {Global_Modules.Please_Choose == "Valuation_Form" &&
                <ValautionForm />
              }

            </>
          )
        })}
      </div>

      {Page.Parent != null && Page.Sub_Parent == null &&
      <Breadcrumbs Parent_Label={Page.Parent.Pagename} Parent_URL={Page.Parent.URL} Page_1={Page.Pagename} />
      }
      {Page.Parent != null && Page.Sub_Parent != null &&
      <Breadcrumbs Parent_Label={Page.Parent.Pagename} Parent_URL={Page.Parent.URL} Parent_1_Label={Page.Sub_Parent.Pagename} Parent_1_URL={Page.Sub_Parent.URL} Page_1={Page.Pagename} />
      }
      {Page.Parent == null &&
      <Breadcrumbs Page_1={Page.Pagename}/>
      }
      </Layout>
    )
  }
}

export default DefaultTemplate;


export const pageQuery = graphql`
  query DefaultTemplateSlug($slug: String!) {

    strapiArticles(URL: { eq: $slug }) {
    Pagename
    id
    Meta_Description
    Meta_Title
    Custom_CSS_Class
    Parent {
      Pagename
      URL
    }
    Sub_Parent {
      Pagename
      URL
    }
    URL
    Layout
    Contact_Person {
      Category
      Designation
      Email
      Name
      Phone
      Image {
        publicURL
      }
    }

    office {
      Address
      Email
      Phone
      Opening_Hours
      Office_Image {
        publicURL
      }
      Office_Name
    }
    Add_Modules {
      Image_Alignment
      Content
      Static_Content
      Title
      CTA_1_Label
      CTA_1_URL
      CTA_2_Label
      CTA_2_URL
      CTA_URL
      CTA_Label
      Content_Alignment
      Title_H1
      Sub_Title
      Help_CTA_Label
      Help_CTA_URL
      Help_Content
      Help_Title
      Services_Links_Title
      Show_Google_Rating
      Add_Accordion {
        Content
        Title
      }
      Choose_Images {
        url
      }
      Upload_Video {
        publicURL
      }
      Services {
        Label
        URL
        Thumbnail_Image {
          publicURL
        }
      }
      Banner_Image {
        internal {
          description
        }
        publicURL
      }
      Choose_Style
      Image {
        internal {
          description
        }
        publicURL
      }
      Service_Title
      Services_Links {
        URL
        Label
      }
      Speak_with {
        Calendly_Link
        Name
        Email
        Image {
          publicURL
        }
        Phone
      }
      Banner_Title
      Embed_Video_URL
      Video_Background_Image {
        internal {
          description
        }
        publicURL
      }
      Layout
      Add {
        CTA_Label
        CTA_URL
        Content
        Image {
          internal {
            description
          }
          publicURL
        }
        Most_Popular
        Publish
        Services
        Title
      }
      Teams {
        Embed_Video_URL
        Name
        Phone
        Designation
        Email
        Image {
          internal {
            description
          }
          publicURL
        }
      }
    }
    Global_Module {
      Please_Choose
    }
  }

  allStrapiTeams(filter: {Publish: {eq: true}}) {
    edges {
      node {
        Name
        Category
        Designation
        Email
        Embed_Video_URL
        Phone
        Image {
          internal {
            description
          }
          publicURL
        }
      }
    }
  }

  strapiGlobalConfig {
    Calendly_Link
  }

  }
`
