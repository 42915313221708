import React, { useState } from 'react';
import { Button, Form, } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
// import { useAuthState, isBrowser, doRedirect/*, storeLoginAttempt*/ } from "@myaccount/website-service";

const StepOne = (props) => {
    const [validated, setValidated] = useState(false);

    // For myaccount
    // const { state: authState, services: authServices } = useAuthState()
    // const isAuthenticated = authServices.isAuthenticated()

    // if (isBrowser() && !isAuthenticated) {
    //   authServices.storeData("auth_return", window.location.href)
    //   doRedirect(process.env.GATSBY_MYACCOUNT_URL)
    // }

    // // My account
    // if (!isAuthenticated) {
    //     return (
    //       <div className="stepone"><h2>Loading...</h2></div>
    //     )
    // }
    
    const saveAndContinue = (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
        }

        setValidated(true);
        props.nextStep();
    }

    return (
        <ScrollAnimation animateIn='fadeIn' animateOnce={true} delay={300}>
            <Form validated={validated}>
                <Form.Group>
                    <div className="custom-float">
                        <Form.Label className="form-label">Name*</Form.Label>
                        <Form.Control
                            className="mb-4"
                            required
                            type="text"
                            name="name"
                            placeholder=""
                            onChange={props?.handleChange}
                            value={props?.inputValues?.name}
                        />
                    </div>
                </Form.Group>
                <Form.Group>
                    <div className="custom-float">
                        <Form.Label className="form-label">Email*</Form.Label>
                        <Form.Control
                            className="mb-4"
                            required
                            type="email"
                            name="email"
                            placeholder=""
                            onChange={props?.handleChange}
                            value={props?.inputValues?.email}
                        />
                    </div>
                </Form.Group>
                <Form.Group>
                    <div className="custom-float">
                        <Form.Label className="form-label">Telephone*</Form.Label>
                        <Form.Control
                            className="mb-4"
                            required
                            type="text"
                            name="telephone"
                            placeholder=""
                            onChange={props?.handleChange}
                            pattern="^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$"
                            value={props?.inputValues?.telephone}
                        />
                    </div>
                </Form.Group>

                <Form.Group className="action-btn">
                    <Button variant="primary" className={'ful'} onClick={saveAndContinue}>Get Started</Button>
                </Form.Group>
            </Form>
        </ScrollAnimation>
    )
}

export default StepOne;