import React, { useState, useEffect } from "react"
import { get, isEmpty } from "lodash";
import { Button } from "react-bootstrap"
import "react-calendar/dist/Calendar.css"
import "./CalendarBlock.scss"
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import enGb from "date-fns/locale/en-GB"
// import ScrollAnimation from "react-animate-on-scroll"
import moment from "moment"
import { setMinutes, setHours, subDays, addDays } from "date-fns"

registerLocale("en-gb", enGb)

const CalendarBlock = props => {

  const getDateFormat = (timestamp) => {
    if (!timestamp)
        return false;
    return moment(timestamp).format('YYYY-MM-DD');
  }

  const getTime = (timestamp) => {
    if (!timestamp)
        return false;
    return moment(timestamp).format('HH:mm');
  }

  const strToFullTime = (time) => {
    const [hours, minutes] = time.split(':');
    const date = new Date(+'1995', '1', +'1', +hours, +minutes, +'0');
    return getTime(date)
  }

  const datePickerFormat = (timestamp) => {
    if (!timestamp)
        return false;
    return moment(timestamp).format('yyyy-MM-DD HH:mm');
  }


  const calendarData = props?.calendarData;
  const [startDate, setStartDate] = React.useState( () => {
        return (props.defaultValue ? new Date(props.defaultValue) : '') || `` 
    }
  );


  const [selectedItems, setSelectedItems] = React.useState({
      negotiator_id: /*calendarData?.negotiator_id ||*/ ``,
      start_time: calendarData?.start_time || ``,
      end_time: calendarData?.end_time || ``
  });

  const [slots, setSlots] = React.useState(() => {
      return []
  })
  // if (calendarData.loading) {
  //     return <Typography variant="body2">Fetching slots...<br /><br /></Typography>
  // }

  const timeIntervals = 5; // get(calendarData, 'slots.duration', 60);
  const duration = get(calendarData, 'duration', 60);
  const dayStart = get(calendarData, 'day_start', 0);
  const dayEnd = get(calendarData, 'day_end', 24);
  const slotes = get(calendarData, 'slots', {});
  const is_unaccompanied = get(calendarData, 'is_unaccompanied', ``);
  const office_id = get(calendarData, 'office_id', ``);
  const minDate = subDays(new Date(), -1)
  const maxDate = addDays(new Date(), 14)

  const updateSlots = (updateDate) => {
      const dateString = getDateFormat(updateDate);
      let daySlots = slotes[dateString] || []
      if (daySlots.length > 0) {
          let availableSlots = [];
          daySlots.map((item, key) => {
              let startTime = item.start_time.split(':');
              availableSlots.push(setHours(setMinutes(new Date(), startTime[1]), startTime[0]));
          });
          return availableSlots
      } else {
          return []
      }
  }

  // @todo: Need to play with start and end slot and load slot dynamic based on date select
  const onSelectFun = (data) => {
      const caleDate = new Date(data);
      const caleDateDay = caleDate.getDate();
      setSlots(updateSlots(caleDate))
  }

  const handleCalendarOpen = () => {
      setSlots(updateSlots(startDate || minDate))
      if (!startDate) {
          setStartDate(minDate)
      }
  }

  const dayClassNameFun = (day) => {
      const dateString = getDateFormat(day);
      let daySlots = slotes[dateString] || []
      return (daySlots.length) ? "has-slots" : "no-slots react-datepicker__day--disabled";
  }

  const timeClassNameFun = (time) => {
      return (time.getHours() >= dayStart &&  time.getHours() <= dayEnd) ? "allowed-slots" : "unallowed-slots";
  }

  const updateSlotDetailsFun = (updatedDate, updateStartDate=false) => {
      const dateString = getDateFormat(updatedDate);
      let daySlots = slotes[dateString] || []
      let selectedTime = getTime(updatedDate);
      if (daySlots && daySlots.length) {

          const matchingSlot  = daySlots.filter((x) => {
              let start_time = x.start_time.toString();
              if (start_time.length === 4)
                  start_time = strToFullTime(start_time)

              return (start_time === selectedTime)
          })

          if (matchingSlot.length > 0) {
              if (updateStartDate) {
                  setStartDate(updatedDate)
              }
              let selectedSlot = get(matchingSlot, '0', {})
              setSelectedItems({
                  negotiator_id: selectedSlot.negotiator_id,
                  start_time: selectedSlot.start_time,
                  end_time: selectedSlot.end_time
              })
              props.handlechange(datePickerFormat(updatedDate))
          } else {
              if (updateStartDate && daySlots.length > 0) {
                  let firstSlot = get(daySlots, '0', {});
                  let firstSlotDateTime = get(firstSlot, 'start_time', '00:00').split(':');
                  let updateDateWithSlot = new Date(new Date(updatedDate).setHours(firstSlotDateTime[0], firstSlotDateTime[1], 0))
                  setStartDate(updateDateWithSlot)
                  setSelectedItems({
                      negotiator_id: firstSlot.negotiator_id,
                      start_time: firstSlot.start_time,
                      end_time: firstSlot.end_time
                  })
                  props.handlechange(datePickerFormat(updatedDate))
              }
          }

      }
  }

  const onChangeFun = (date) => {
      updateSlotDetailsFun(date, true)
      // setStartDate(date)
  }

  if (props?.defaultValue && !selectedItems?.start_time) {
      updateSlotDetailsFun(props.defaultValue)
  }

  if (!startDate && !isEmpty(slotes)) {
      let firstAvailableDate = ''
      let slotKeys = Object.keys(slotes);
      for (var i = 0; i < slotKeys.length; i++) {
          if (!isEmpty(slotes[slotKeys[i]])) {
              firstAvailableDate = slotKeys[i];
              break;
          }
      }
      if (firstAvailableDate) {
          let dateToSelect = new Date(firstAvailableDate)
          updateSlotDetailsFun(dateToSelect, true)
          setSlots(updateSlots(dateToSelect))
      }
}

  return (
    <div className="calendar-block">
      <div>
          <div className="d-none d-md-block  desktop_view_date">
            <DatePicker
              required
              selected={startDate}
              onChange={onChangeFun}
              showTimeSelect
              timeIntervals={timeIntervals}
              includeTimes={slots}
              dateFormat="yyyy-MM-dd HH:mm"
              timeFormat="HH:mm"
              // customInput={<CustomPhoneNumber />}
              minDate={minDate}
              onSelect={onSelectFun}
              shouldCloseOnSelect={false}
              maxDate={maxDate}
              minTime={setHours(setMinutes(new Date(), 0), dayStart)}
              maxTime={setHours(setMinutes(new Date(), 0), dayEnd)}
              // withPortal={true}
              calendarClassName={`myaccount-calendar`}
              dayClassName={dayClassNameFun}
              timeClassName={timeClassNameFun}
              placeholderText="Click to select slot"
              onCalendarOpen={handleCalendarOpen}
              inline
            />
          </div>
      </div>
    </div>
  )
}

export default CalendarBlock
